import styled from "styled-components";

export const PostNavRightSpan = styled.span`
    color: #546E7A;
    font-family: 'HurmeGeoSans', sans-serif;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    position: absolute;
    text-transform: uppercase;
    top: 40px;
    left: 62px;

    @media only screen and (max-width: 960px) {
        left: 40px;
        top: 30px;
    }
`