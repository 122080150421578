import styled from "styled-components";

export const PostNavRightAnchor = styled.a`
background-color: #fafbfb;
border: 2px solid #fafbfb;
border-radius: 5px;
color: #263238;
display: block;
font-family: 'HurmeGeoSans', sans-serif;
line-height: 1.35;
padding: 60px 60px 40px;
text-decoration: none;
padding-right: 100px;

@media only screen and (max-width: 960px) {
    padding: 50px 40px 25px;
    padding-right: 75px;
}

&:before {
    border-left: 1px solid #546E7A;
    border-bottom: 1px solid #546E7A;
    content: '';
    display: block;
    height: 20px;
    opacity: 0.5;
    position: absolute;
    transform: rotate(
45deg);
    transition: all 0.2s ease-in-out;
    width: 20px;
    right: 50px;
    transform: rotate(
-135deg);
}
`