import React, {useState} from "react";
import { gql, useMutation } from "@apollo/client"
import "./styles.scss"

// Create a GraphQL mutation for comment submissions.
const commentSubmitQuery = gql`
	mutation($author: String, $commentOn: Int, $content: String, $authorEmail: String, $authorUrl: String) {
		createComment(
			input: {
				clientMutationId: "CreateComment"
				author: $author
				commentOn: $commentOn
				content: $content
				authorEmail: $authorEmail
                authorUrl: $authorUrl
			}
		) {
			success
		}
	}
`;


// Our main component function.
const CommentForm = ({...props}) => {
    const [comment, setComment] = useState('')
    const [author, setAuthor] = useState('')
    const [email, setEmail] = useState('')
    const [url, setUrl] = useState('')

    // The database ID of the current post
    const post = props.databaseId

    // Handles the mutation. Replaces form with appropriate message after submission.
    const [addComment, {data, loading, error}] = useMutation(commentSubmitQuery)
    // Line breaks are for additional padding. Keeps the text on screen.
    if (loading) return <p><br/><br/><br/><br/><br/>Submitting...</p>;
    if (error) return <p><br/><br/><br/><br/><br/>Something went wrong. Please refresh the page and try again.</p>;
    if (data) return <p><br/><br/><br/><br/><br/>Thanks! Your comment has been submitted for review.</p>;

	// Handles input change events.
	const handleInputChange = (e) =>{
		const target = e.target;
		const value = e.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
        const obj = {
            'author': setAuthor,
            'email': setEmail,
            'url': setUrl,
            'comment': setComment,
        };

        const updateState = obj[name];
		// Update state for the field being changed
		updateState(value);
	}

	// Renders the comment form elements.
    const renderCommentForm = () => {
        return (
            <div className="">
                <form className="comment-respond" onSubmit={(e) => {
                    // Prevent default form submit behavior.
                    e.preventDefault();
                    // Run the mutation.
                    addComment({
                        variables: {
                            author: author,
                            commentOn: post,
                            content: comment,
                            authorEmail: email,
                            authorUrl: url,
                        },
                    });
                }}>
                    <h3>Leave a comment</h3>
                    <label htmlFor="author">Name</label>
                    <input name="author" type="author" value={author} onChange={(e) => handleInputChange(e)} maxLength="200" required/>
                    <label htmlFor="email">Email</label>
                    <input name="email" type="email" value={email} onChange={(e) => handleInputChange(e)} maxLength="350" required/>
                    <label htmlFor="author">Website (optional)</label>
                    <input name="url" type="text" value={url} onChange={(e) => handleInputChange(e)} maxLength="350" />
                    <label htmlFor="comment">Comment</label>
                    <textarea name="comment" type="text" value={comment} onChange={(e) => handleInputChange(e)} maxLength="3000" required/>
                    <br />
                    <input name="submit" className="form-submit" type="submit" value="Post Comment" />
                </form>
            </div>
        );
    }


	// Render the comment form.
	return(
		// Check comment status from component state and display messages or form.
        <>
            {renderCommentForm()}
        </>
    )
}

export default CommentForm;