import React from "react"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import { getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Layout from "../components/layout/Layout"
import Seo from "../components/layout/seo"
import PostSiteInner from '../components/post/PostSiteInner'
import PostNav from '../components/post/PostNav'
import { Wrap } from "../components/styles/Wrap.styled"
import CommentList from "../components/comments/comment-list"
import CommentForm from "../components/comments/comment-form"
import withPreview from '../components/withPreview/index.js';


const BlogPostTemplate = ({ data: { previous, next, post } }) => {

  const image = getImage(post?.featuredImage?.node?.localFile)
  const featuredImage = {
    fluid: post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post?.featuredImage?.node?.alt || ``,
  }

  var props = {
    id: post?.id,
    databaseId: post?.databaseId,
    title: parse(post?.title),
    postURI: post?.uri,
    authorName: post?.author?.node?.name,
    authorUri: post?.author?.node?.uri,
    authorBio: post?.author?.node?.description,
    authorImage90: post.author?.node?.authorImage?.authorImage90px?.sourceUrl,
    authorImage150: post.author?.node?.authorImage?.authorImage150px?.sourceUrl,
    postUpdated: post?.modified,
    featuredImage: image,
    featuredImageAlt: featuredImage?.alt,
    postContent: parse(post?.content),
    postExcerpt: post?.excerpt,
    previousURI: previous?.uri,
    previousTitle: previous?.title,
    nextURI: next?.uri,
    nextTitle: next?.title,
  }
  

  // Temporary placeholder div until CTA is created
  const divStyle = {
    minHeight: '300px',
    minWidth: '200px',
  }

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <PostSiteInner {...props}/>
      <PostNav {...props}/>
      <Wrap style={{maxWidth: '800px'}}>
        <CommentList {...props}/>
        <div style={{minHeight: '300px'}}>
          <CommentForm {...props}/>
        </div>
      </Wrap>
      <div style={divStyle}></div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      databaseId
      uri
      excerpt
      content
      title
      modified(formatString: "MMMM DD, YYYY")
      author {
        node {
          name
          uri
          description
          authorImage {
            authorImage90px {
              sourceUrl
            }
            authorImage150px {
              sourceUrl
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 50
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`


const PREVIEW_QUERY = gql`
  query getPreview($id: Int!) {
    postBy(postId: $id) {
      title
      revisions {
        nodes {
          id
          title
          content
        }
      }
    }
  }
`;

export default withPreview({ preview: PREVIEW_QUERY })(BlogPostTemplate);