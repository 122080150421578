import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

const divStyle = {
    maxWidth: '1050px',
    margin: '0 auto 48px',
    textAlign: 'center',
}

const imgStyle = {
    borderRadius: '5px',
}

export default function PostFeaturedImage({...props}) {
    return (
        <div style={divStyle}>
            {props.featuredImage !== undefined 
                        ? <GatsbyImage alt={props.featuredImageAlt} image={props.featuredImage} style={imgStyle}/> 
                        : <></>}
        </div>
    )
}
