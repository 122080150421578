import styled from 'styled-components'

export const EntryMetaContainer = styled.div`
margin-bottom: 16px !important;
display: inline-block;
margin: 0 auto;
text-align: left;
box-sizing: inherit;
word-wrap: break-word;
font-size: 2rem;
`