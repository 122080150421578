import React from "react"
import { ContentMainStyle } from '../styles/ContentMainStyle.styled'
import PostAuthorBox from './PostAuthorBox'


const articleStyle = {
    marginBottom: '40px',
}

const divStyle = {
  // Left empty on purpose
  // Can add styles here if needed
}

const PostContent = ({...props}) => {
    return (
        <>
            <ContentMainStyle>
                <article style={articleStyle}>
                    <div style={divStyle} className='entry-content' itemProp='text'>
                        {props.postContent}
                    </div>
                </article>
            {/* Still need to implement these features: */}
            {/* <EntryCTA />
                <EntryFooter /> */}
                <PostAuthorBox {...props}/>
            </ContentMainStyle>
            
        </>
    )
}

export default PostContent
