import React from "react";
import { useQuery, gql } from "@apollo/client"
import { Wrap } from "../styles/Wrap.styled";
import "./styles.scss"

// Create a GraphQL query for the comment list.
// Must specify a `first` value to get more than 10 results
const commentQuery = gql`
  query ($postId: ID!) {
    post(id: $postId) {
      comments(first: 500) {
        nodes {
          content
          id
          author {
            node {
              name
              url
            }
          }
        }
      }
    }
  }
`;

// Main component function.
export default function CommentList({...props}){
    const postId = props.id;
    const { data, loading, error } = useQuery(commentQuery, {
        variables: { postId }
        });
    
    // Line breaks are for additional padding
    if (loading) return <Wrap>Loading comments...<br/><br/><br/><br/></Wrap>;
    if (error) return <Wrap>Error loading comments...<br/><br/><br/><br/></Wrap>;

    // Display message if there are no comments to show.
    // Line breaks are for additional padding
    if (data.post.comments.nodes.length < 1) return <Wrap>This post does not have any comments.<br/><br/><br/><br/></Wrap>;

    // Assign comments to variable and reverse the order.
    const comments = [...data.post.comments.nodes].reverse()
    
    return (
        // Display the comment list.
          <div className="entry-comments" id="comments">
            <h3>Reader Comments ({data.post.comments.nodes.length})</h3>
            <ol className="comment-list">
                {comments.map((comment) => (
                    <li className="comment" key={comment?.author}>
                      <article itemprop="comment" itemscope="" itemtype="https://schema.org/Comment">
                        <header className="comment-header">
                          <p class="comment-author" itemprop="author" itemscope="" itemtype="https://schema.org/Person">
                              <span itemprop="name"><a href={comment?.author?.node?.url} class="comment-author-link" rel="external nofollow" itemprop="url">{comment?.author?.node?.name}</a></span> <span class="says">says</span>	
                          </p>
                        </header>
                        <div className="comment-content" itemprop="text" dangerouslySetInnerHTML={{ __html: comment.content }} />
                      </article>
                    </li>
                ))}
            </ol>
          </div>
    );
    
}