import React from "react"
import { Link } from "gatsby"


const divStyle = {
    marginRight: '20px',
    display: 'block',
    float: 'left',
    lineHeight: '1.45',
    paddingTop: '0',
    boxSizing: 'inherit',
    textAlign: 'left',
}

const emStyle = {
    color: '#546E7A',
    display: 'inline-block',
    fontSize: '1.3rem',
    fontStyle: 'italic',
}

const aStyle = {
    display: 'block',
    color: '#263238',
    fontFamily: "'HurmeGeoSans', sans-serif",
    fontSize: '1.4rem',
    fontWeight: '700',
    textDecoration: 'none',
    transition: "color 0.2s ease-in-out, background-color 0.2s ease-in-out",
}

const spanStyle = aStyle;

export default function PostAuthor({...props}) {
    return (
        <>
            {/* Post Author Goes Here */}
            <div style={divStyle}>
                <em style={emStyle}>written by</em>
                <Link to={props.authorUri} style={aStyle}>{props.authorName}</Link>
            </div>

            {/* Post Date Goes Here */}
            <div style={divStyle}>
                <em style={emStyle}>last updated</em>
                <span style={spanStyle}>{props.postUpdated}</span>
            </div>
        </>
    )
}
