import styled from "styled-components";

export const PostNavRightStyle = styled.div`
clear: none;
float: right;
position: relative;
width: 49.8%;


@media only screen and (max-width: 1075px) {
    margin: 0;
}

@media only screen and (max-width: 800px) {
    clear: both;
    float: none;
    margin: 5px 0;
    width: 100%;
}
`