import React from "react"
import { HOneEntry } from "../styles/HOneEntry.styled"
import PostEntryMeta from '../post/PostEntryMeta'

const headerStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '32px',
	  textAlign: 'center',
};

export default function PostEntryHeader({...props}) {
  return (
    <header style={headerStyle}>
      <HOneEntry>{props.title}</HOneEntry>
      <PostEntryMeta {...props}/>
    </header>
  );
}
