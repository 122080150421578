import React from "react"
import { SiteInnerStyle } from "../styles/SiteInner.styled";
import PostEntryHeader from "./PostEntryHeader";
import PostFeaturedImage from "./PostFeaturedImage";
import { ContentSidebarWrap } from "../styles/ContentSidebarWrap.styled";
import PostContent from "./PostContent";
import Sidebar from "../sidebar/Sidebar";
import WidgetEmailForm from '../sidebar/WidgetEmailForm'
import WidgetDownload from '../sidebar/WidgetDownload'
import WidgetFeaturedPostList from '../sidebar/WidgetFeaturedPostList'


const PostSiteInner = ({...props}) => {
  return (
    <SiteInnerStyle>
      <PostEntryHeader {...props}/>

      <PostFeaturedImage {...props}/>

      <ContentSidebarWrap>
        <PostContent {...props}/>

        <Sidebar>
          <WidgetEmailForm />
          <WidgetDownload />
          <WidgetFeaturedPostList />
        </Sidebar>
      </ContentSidebarWrap>
    </SiteInnerStyle>
  );
};

export default PostSiteInner;
