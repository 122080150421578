import React from "react"
import silhouette from '../../assets/avatar-90px.jpg'
import { Link } from "gatsby"

const divStyle = {
    height: '55px',
    width: '55px',
    marginRight: '20px',
    left: '0',
    paddingTop: '0',
    position: 'relative',
    top: 0,
    display: 'block',
    float: 'left',
    lineHeight: 1.45,
    boxSizing: 'inherit',
    textAlign: 'left',
    wordWrap: 'break-word',
    fontWeight: 400,
}

const aStyle = {
    color: '#0066CC',
    textDecoration: 'underline',
    transition: 'color 0.2s ease-in-out, background-color 0.2s ease-in-out',
    backgroundColor: 'transparent',
}

const imgStyle = {
    borderRadius: '50%',
    float: 'left',
    height: 'auto',
    verticalAlign: 'top',
    maxWidth: '100%',
    borderStyle: 'none',
    boxSizing: 'inherit',
    aspectRatio: 'auto 150 / 150',
    width: '150px',
}


export default function PostAuthorAvatar({...props}) {
    return (
        <div style={divStyle}>
            <Link to={props.authorUri} style={aStyle}>
                <img alt=""
                    src={props.authorImage90 !== undefined ? props.authorImage90 : silhouette}
                    style={imgStyle}/>
            </Link>
        </div>
        
    )
}
