import React from "react"
import { EntryMetaContainer } from '../styles/EntryMetaContainer.styled'
import PostAuthorAvatar from './PostAuthorAvatar'
import PostAuthorPostDate from './PostAuthorPostDate'
import PostSocialShare from './PostSocialShare'

const divStyle = {
    marginTop: '32px',
    textAlign: 'center',
    display: 'block',
}

export default function PostEntryMeta({...props}) {
    return (
        <div style={divStyle}>
            <EntryMetaContainer>
                <PostAuthorAvatar {...props}/>
                <PostAuthorPostDate {...props}/>
                {/* <PostSocialShare {...props}/> */}
            </EntryMetaContainer>
        </div>
        
    )
}
