import React from "react"
import { AuthorBio } from '../styles/AuthorBio.styled'
import parse from "html-react-parser"
import { Link } from "gatsby"
import silhouette from '../../assets/avatar-150px.jpg'

const sectionStyle = {
    wordWrap: 'break-word',
    marginBottom: '40px',
    padding: '0',
    borderBottom: '1px solid #E7EBED',
    borderTop: '1px solid #E7EBED',
    marginTop: '24px',
    paddingBottom: '24px',
    paddingTop: '24px',
    minHeight: '150px',
}

const imgStyle = {
    aspectRatio: 'auto 90 / 90',
    maxWidth: '100%',
    height: 'auto',
    verticalAlign: 'top',
    borderRadius: '50%',
    float: 'left',
    marginRight: '32px',
    width: '75px',
}

const h4Style = {
    fontSize: '2rem',
    fontWeight: '600',
    marginBottom: '10px',
}

const aStyle = {
    color: '#11171A',
    textDecoration: 'none',
}

export default function PostAuthorBox({...props}) {
    return (
        <section style={sectionStyle}>
            <img style={imgStyle}
            src={props.authorImage150 !== undefined ? props.authorImage150 : silhouette} 
            alt="" />
            <h4 style={h4Style}><Link to={props.authorUri} style={aStyle}>{props.authorName}</Link></h4>
            <div>
                <AuthorBio>
                {props.authorBio != null ? parse(props.authorBio) : ''}
                </AuthorBio>
            </div>
        </section>
    )
}
