import styled from 'styled-components'

export const HOneEntry = styled.h1`
    @media only screen and (max-width: 960px){
        font-size: 48px;
        font-size: 4.8rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 34px;
        line-height: 1.15;
        letter-spacing: -0.5px;
    }
`
    
