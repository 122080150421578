import React from "react"

import { PostNavWrap } from '../styles/PostNavWrap.styled'
import PostNavLeft from "./PostNavLeft"
import PostNavRight from "./PostNavRight"

const divStyle = {
    margin: '10px 0 60px',
}

export default function PostNav({...props}) {
    return (
        <div style={divStyle}>
            <PostNavWrap>
                {props.previousTitle === undefined 
                    ? (<></>)
                    : <PostNavLeft {...props}/>}
                {props.nextTitle === undefined 
                    ? (<></>) 
                    : <PostNavRight {...props}/>}
            </PostNavWrap>
            {/* <hr> to force container div to stretch around floated nav buttons */}
            <hr style={{borderTop: '1px solid rgba(255,255,255,0)'}}/>
        </div>
    )
}