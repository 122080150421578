import styled from "styled-components"

export const ContentMainStyle = styled.main`
box-sizing: border-box;
margin: 0;
max-width: 750px !important;
float: left;
overflow: hidden;

@media only screen and (max-width: 1250px) {
    float: none;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
}
`