import styled from "styled-components";

export const PostNavLeftStyle = styled.div`
    clear: none;
    float: left;
    position: relative;
    width: 49.8%;
    text-align: right;

    @media only screen and (max-width: 1075px) {
        margin: 0;
    }

    @media only screen and (max-width: 800px) {
        clear: both;
        float: none;
        margin: 5px 0;
        width: 100%;
    }
`