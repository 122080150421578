import React from "react"
import { PostNavRightStyle } from '../styles/PostNavRightStyle.styled'
import { PostNavRightSpan } from '../styles/PostNavRightSpan.styled'
import { PostNavRightAnchor } from '../styles/PostNavRightAnchor.styled'


export default function PostNavRight({...props}) {
    return (
        <PostNavRightStyle>
            <PostNavRightSpan>Next Article:</PostNavRightSpan>
                <strong>
                    <PostNavRightAnchor href={props.nextURI}>
                        {props.nextTitle}
                    </PostNavRightAnchor>
                </strong>
        </PostNavRightStyle>
    )
}