import React from "react"
import { PostNavLeftStyle } from '../styles/PostNavLeftStyle.styled'
import { PostNavLeftSpan } from '../styles/PostNavLeftSpan.styled'
import { PostNavLeftAnchor } from '../styles/PostNavLeftAnchor.styled'


export default function PostNavLeft({...props}) {
    return (
        <PostNavLeftStyle>
            <PostNavLeftSpan>Previous Article:</PostNavLeftSpan>
                <strong>
                    <PostNavLeftAnchor href={props.previousURI}>
                        {props.previousTitle}
                    </PostNavLeftAnchor>
                </strong>
        </PostNavLeftStyle>
    )
}